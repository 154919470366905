import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider } from "styled-components";
import DateFnsUtils from "@date-io/date-fns";
import maTheme from "./theme";
import { Helmet } from "react-helmet";
import { asyncConstants } from "controllers/constants-route/redux-store/constants/constants";
import { asyncFrontContentByLocale, onConditionClear } from "controllers/locale/redux-store/locale/locale";
import { __localeStorage } from "helper/location/location";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { reselectTheme } from "redux-store/theme/theme";
import { LangEnum } from "./controllers/admin-expo/enums";
import Loader from "./components/Loader/Loader";
import "./App.css";
import VisitPayPage from "./pages/Account/pages/Visits/VisitPayPage";

const ModeratorLayout = lazy(() => import("./pages/AccountModerator/ModeratorLayout"));
const PageITLeader = lazy(() => import("./pages/Form/PageITLeader"));
const AdminLayout = lazy(() => import("./pages/AdminLayout/AdminLayout"));
const PageToken = lazy(() => import("pages/Auth/PageToken"));
const PageResetPassword = lazy(() => import("pages/Auth/PageResetPassword"));
const PageRegistration = lazy(() => import("pages/Auth/PageRegistration"));
const AuthRoute = lazy(() => import("components/AuthRoute/AuthRoute"));
const Page404 = lazy(() => import("pages/404/404"));
const PageDialog = lazy(() => import("pages/Doalog/Dialog"));
const PayResultPage = lazy(() => import("./pages/Pay/PayResultPage"));
const PaymentPage = lazy(() => import("./pages/Pay/PaymentPage"));
const SiteLayout = lazy(() => import("./pages/Site/SiteLayout"));
const ResultsPage = lazy(() => import("./pages/Results/ResultsPage"));
const AccountLayout = lazy(() => import("./pages/Account/AccountLayout"));
const Developer = lazy(() => import("./pages/Developer/Developer"));
const TokenConfirmPage = lazy(() => import("./pages/Account/pages/TokenConfirmPage"));

const App = () => {
    const reduxDispatch = useDispatch();
    const theme = useSelector(reselectTheme);
    // const routeParams = useParams();
    // const condition = useSelector(reselectFrontContentLocaleCondition);
    // const content = useSelector(reselectFrontContentLocale);
    // const condition2 = useSelector(reselectConstants_condition);
    const handleOnRequestConstants = () => {
        reduxDispatch(asyncConstants());
    };
    const path = () => window.location.pathname.replace(/\/$/g, "");
    const locale = path().split("/")[1];
    const urlSearchParams = new URLSearchParams(window.location.search);
    const cpromo = urlSearchParams.get("cpromo");
    const handleOnRequestLocale = () => {
        if (locale === "ru" || locale === "en" || locale === "cn") {
            reduxDispatch(asyncFrontContentByLocale(locale));
            localStorage.setItem("locale", locale);
        } else {
            reduxDispatch(asyncFrontContentByLocale(LangEnum.RU));
            reduxDispatch(onConditionClear());
        }
    };

    ["9", "10", "11"].forEach(elem => {
        localStorage.setItem(`firstLoadDay-${elem}`, "true");
    });
    localStorage.setItem("firstLoadAnonsBlock", "true");

    React.useEffect(() => {
        handleOnRequestConstants();
        handleOnRequestLocale();
        if (cpromo) localStorage.setItem("cpromo", cpromo);
    }, [window.location.pathname, cpromo]);

    return (
        <>
            <Helmet titleTemplate="%s | Material App" defaultTitle="Международный форум Kazan Digital Week" />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <MuiThemeProvider theme={maTheme[theme.currentTheme || 0]}>
                    <ThemeProvider theme={maTheme[theme.currentTheme || 0]}>
                        <Suspense fallback={<Loader />}>
                            <Router>
                                <Switch>
                                    <Route path="/" exact component={() => <Redirect to={`/ru`} />} />
                                    <Route
                                        path="/:ru(ru|en|cn)"
                                        exact
                                        component={() => <Redirect to={path() + `/site`} />}
                                    />
                                    <Route path="/confirmation" exact component={TokenConfirmPage} />
                                    <Route path="/form/it-leader" exact component={PageITLeader} />
                                    <Route path="/:ru(ru|en|cn)/site" component={SiteLayout} />
                                    <AuthRoute
                                        type="user"
                                        path="/:ru(ru|en|cn)/user/visits/:visit"
                                        exact
                                        component={VisitPayPage}
                                    />
                                    <Route path="/:ru(ru|en|cn)/user" component={AccountLayout} />
                                    <Route path="/:ru(ru|en|cn)/admin" component={AdminLayout} />
                                    <Route path="/moderation" component={ModeratorLayout} />
                                    <Route
                                        path="/:ru(ru|en|cn)/user/partners"
                                        exact
                                        component={() => <Redirect to={"/" + locale + `/site/organizersAndPartners`} />}
                                    />
                                    <Route
                                        path="/:ru(ru|en|cn)/(sign-up|sign-up-media|sign-up-org|sign-up-volunteer|sign-up-hackathon)/"
                                        exact
                                        component={PageRegistration}
                                    />
                                    <Route path="/:ru(ru|en|cn)/404" exact component={Page404} />
                                    <Route path="/:ru(ru|en|cn)/dialog" exact component={PageDialog} />
                                    <Route path="/:ru(ru|en|cn)/login" exact component={PageToken} />
                                    <Route path="/:ru(ru|en|cn)/reset-password" exact component={PageResetPassword} />
                                    <Route path="/:ru(ru|en|cn)/pay/:status" exact component={PayResultPage} />
                                    <Route path="/:ru(ru|en|cn)/developer" exact component={Developer} />
                                    <AuthRoute
                                        type="user"
                                        path="/:ru(ru|en|cn)/payment"
                                        exact
                                        component={PaymentPage}
                                    />
                                    <Route path="/:ru(ru|en|cn)/results" component={ResultsPage} />
                                    <Redirect to={`/${__localeStorage()}/404`} />
                                </Switch>
                            </Router>
                        </Suspense>
                    </ThemeProvider>
                </MuiThemeProvider>
            </MuiPickersUtilsProvider>
        </>
    );
};

// export default connect((store: any) => ({
//     theme: store.theme,
//     localization: store.locale,
// }))(App);

export default App;
